import { ENDPOINTS, getUserInfo, httpService, Operation, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Branch, Department, Employee } from "@models";
import { Action, ComplexHeader, ExportData, Filterable, MyRingLoader, Table, TableVerticalConfigs } from "@shared";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AddEmployee, LineManager, UploadEmployees } from "@components";
import { FiPlus, FiUpload } from "react-icons/fi";
import Pagination from "react-responsive-pagination";
import { AccessLevel, FetchEmployeesVM } from "@viewModels";

const ListEmployees = () => {
  const currentUser = getUserInfo();

  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);
  const [listMode, setListMode] = useState<"grid" | "list">("grid");
  const [currentPage, setCurrentPage] = useState(1);

  const fetchEmployees = useFetch<PaginatedResult<FetchEmployeesVM>>(
    {
      endPoint: ENDPOINTS.employees,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<FetchEmployeesVM>()
  );

  const fetchDepartments = useFetch<PaginatedResult<Department>>(
    {
      endPoint: ENDPOINTS.departments,
      reload: reload,
      ignorePagination: true,
    },
    new PaginatedResult<Department>()
  );

  const fetchBranches = useFetch<PaginatedResult<Branch>>(
    {
      endPoint: ENDPOINTS.branches,
      reload: reload,
      ignorePagination: true,
      // queryStrings:
      //   currentUser.accessLevel.toLowerCase() ===
      //   AccessLevel.Branch.toLowerCase()
      //     ? `page=1&size=-1&payrollBranchId=${currentUser.branchId}`
      //     : `page=1&size=-1`,
    },
    new PaginatedResult<Branch>()
  );

  useEffect(() => {}, [fetchEmployees?.isFetching, fetchDepartments?.isFetching, fetchBranches?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "employeeId", title: "employee Id" },
    { key: "name", title: "Full Name" },
    { key: "typeOfContract", title: "type Of Contract" },
    { key: "location", title: "Branch" },
    { key: "departmentName", title: "Department" },
    { key: "designationName", title: "Designation" },
    // { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<Employee>[] = [
    {
      key: "employeeId",
      title: "emp. id",
      format: "text",
      operation: Operation.like,
    },
    { key: "name", title: "name", format: "text", operation: Operation.like },
    {
      key: "departmentId",
      title: "department",
      format: "select",
      data: [
        ...fetchDepartments?.data?.items?.map((d) => ({
          id: d.id,
          name: d.name,
        })),
      ],
    },
    {
      key: "branchId",
      title: "branch",
      format: "select",
      data: [
        ...fetchBranches?.data?.items?.map((d) => ({
          id: d.id,
          name: d.location,
        })),
      ],
    },
    {
      key: "dateOfEmployment",
      title: "Employment Date",
      format: "dateRange",
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (e: Employee) => {
        onProfileClickHandler(e);
      },
      title: "Profile",
      color: "primary",
    },
    {
      key: "",
      actionType: "badge",
      click: (e: Employee) => {
        onSetLineManagerClickHandler(e);
      },
      title: "Line Manager",
      color: "dark",
    },
  ];

  const onProfileClickHandler = (e: Employee) => {
    navigate(`/employees/profile/${e.id}`);
  };

  const onSetLineManagerClickHandler = (e: Employee) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 900,
      html: (
        <LineManager
          employeeId={e.id}
          lineManagerId={e?.lineManagerId ?? 0}
          callback={() => setReload((prev) => (prev === undefined ? true : !prev))}
        />
      ),
    });
  };

  const onAddUserClickHandler = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 900,
      html: <AddEmployee callback={() => setReload((prev) => (prev === undefined ? true : !prev))} />,
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const handleGridListPageChange = (page: number, size: number) => {
    setCurrentPage(page);
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const onListModeChange = (e: any, mode: "grid" | "list") => {
    e.preventDefault();

    const as = document.getElementsByClassName("listMode") as unknown as [];

    for (let a of as) {
      // (a as any).classList.remove("active");
      (a as any).classList.toggle("active");
    }

    e.target.classList.add("active");

    setListMode(mode);
  };

  const onExport: ExportData = {
    data: () => excelData(),
    fileName: `Employees Report - ${new Date().toLocaleDateString()}`,
  };

  const excelData = async () => {
    queryStrings.set("size", (0).toString());

    const res = await httpService(ENDPOINTS.employees, queryStrings).getAll();
    if (res.status === 200) {
      return (res.data as PaginatedResult<FetchEmployeesVM>)?.items.map((e) => ({
        Name: e.name,
        Contract_Type: e.typeOfContract,
        Phone: e.phone,
        Birth_Date: e.birthDate,
        Age_Group: e.ageGroup,
        Job_Grade: e.jobGrade,
        Email: e.email,
        Source_Of_Income: e.sourceOfIncome,
        Payroll_group: e.payrollGroup,
        Department: e.departmentName,
        Designation: e.designationName,
        Branch: e.location,
        Employee_Id: e.employeeId,
        Gender: e.gender,
        Sub_Branch: e.subBranch,
        Salary: e.salary,
        Allowance: e.allowance,
        Status: e.status,
        Date_Of_Employment: e.dateOfEmployment !== null ? new Date(e.dateOfEmployment).toLocaleDateString() : "",
        Termination_Date: e.terminationDate !== null ? new Date(e.terminationDate).toLocaleDateString() : "",
        Diff: getDateDifference(e.dateOfEmployment, e.terminationDate),
        Termination_Reason: e.terminationReason,
        Termination_Remarks: e.terminationRemarks,
      }));
    }

    return [];
  };

  const getDateDifference = (date1: string, date2: string) => {
    if (!date1 || !date2) return "";

    const d1 = new Date(date1);
    const d2 = new Date(date2);

    let years = d2.getFullYear() - d1.getFullYear();
    let months = d2.getMonth() - d1.getMonth();
    let days = d2.getDate() - d1.getDate();

    // Adjust if the difference in months or days is negative
    if (days < 0) {
      months -= 1;
      days += new Date(d2.getFullYear(), d2.getMonth(), 0).getDate(); // get days in the previous month
    }
    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return `${years} years, ${months} months, and ${days} days`;
  };

  const onUploadClickHandler = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <UploadEmployees callback={() => setReload((prev) => (prev === undefined ? true : !prev))} />,
    });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Employees</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button className="btn btn-sm btn-warning rounded-circle m-1" onClick={onUploadClickHandler}>
            <FiUpload />
          </button>
          <button className="btn btn-sm btn-primary rounded-circle m-1" onClick={onAddUserClickHandler}>
            <FiPlus />
          </button>
          <TableVerticalConfigs exportAsXSLS={onExport} filters={filters} />
          <div className="view-icons">
            <a
              href="#!"
              onClick={(e) => onListModeChange(e, "grid")}
              className="listMode grid-view btn btn-link active"
            >
              <i className="fa fa-th" />
            </a>
            <a href="#!" onClick={(e) => onListModeChange(e, "list")} className="listMode list-view btn btn-link">
              <i className="fa fa-bars" />
            </a>
          </div>
        </div>
      </div>
      {listMode === "list" ? (
        <div className="row mt-3">
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={fetchEmployees?.data ?? []}
            headers={headers}
            isFetchingPage={fetchEmployees.isFetching}
            showCounter
            onPageChange={handlePageChange}
            paginationClass="row mt-3"
          />
        </div>
      ) : (
        <div className="row staff-grid-row mt-5">
          <>
            {fetchEmployees.isFetching ? (
              <MyRingLoader />
            ) : fetchEmployees?.data?.items?.length > 0 ? (
              fetchEmployees?.data?.items?.map((e: FetchEmployeesVM, idx) => (
                <div key={idx} className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                  <div className="profile-widget">
                    <div className="profile-img">
                      <NavLink to={`/employees/profile/${e.id}`} className="avatar">
                        <img src={e?.imgURL ?? `/assets/img/profiles/avatar-02.jpg`} alt="" />
                      </NavLink>
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                      <NavLink to={`/employees/profile/${e.id}`}>{e?.name}</NavLink>
                    </h4>
                    <div className="small text-muted">{e?.location}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className="container">
                <section className="d-flex flex-column align-items-center justify-content-center">
                  {/* <h3 className='alert text-danger'>Oops...</h3> */}
                  <h3 className="alert text-info">No data to show.</h3>
                </section>
              </div>
            )}

            <Pagination
              total={fetchEmployees?.data?.totalPages ?? 0}
              current={currentPage}
              maxWidth={10}
              onPageChange={(page) => handleGridListPageChange(page, fetchEmployees?.data?.size)}
            />
          </>
        </div>
      )}
    </div>
  );
};

export default ListEmployees;
